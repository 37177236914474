import React, { ReactNode } from 'react';
import { CopyToClipboardButton } from 'src/components/CopyToClipboardButton/CopyToClipboardButton';
import { truncateString } from 'src/utils/generalUtils/generalUtils';

export const OperatorInfo = ({
  operatorName,
  operatorId,
  region,
  additionalItems,
}: {
  operatorName?: string;
  operatorId?: string;
  region?: string;
  additionalItems?: ReactNode[];
}) => {
  return (
    <ul className="job-visualization-node__info">
      {operatorId && (
        <li>
          {operatorId}
          <CopyToClipboardButton
            data={operatorId}
            icon="content_copy"
            variant="link"
          />
        </li>
      )}
      {operatorName && (
        <li>
          {truncateString(operatorName, 35)}
          <CopyToClipboardButton
            data={operatorName}
            icon="content_copy"
            variant="link"
          />
        </li>
      )}
      {region && (
        <li>
          {region}
          <CopyToClipboardButton
            data={region}
            icon="content_copy"
            variant="link"
          />
        </li>
      )}
      {additionalItems?.map((item) => item)}
    </ul>
  );
};
