import React, { ReactElement, useContext, useState, useEffect } from 'react';
import { UserContext } from 'src/context/userContext';
import { Link } from 'react-router-dom';
import SiteLogo from 'src/components/SiteLogo/SiteLogo';
import { KatIcon, KatLink } from '@amzn/katal-react';
import { AccountSwitcher } from 'src/components/AccountSwitcher/AccountSwitcher';

const SiteHeader = (): ReactElement => {
  const user = useContext(UserContext);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const imgSrc =
    'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=' + user.name;

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    window.addEventListener('resize', closeMenu);
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        closeMenu();
      }
    });
  }, []);

  return (
    <>
      <header>
        <div className="site-header">
          <div className="site-header__heading-container">
            <button
              className="site-header__menu-button"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <KatIcon name={menuOpen ? 'close' : 'menu'} size="small" />
            </button>
            <h1 className="site-header__logo-container">
              <Link to="/">
                <SiteLogo className="site-header__logo" />
              </Link>
            </h1>
          </div>
          <div className="site-header__content">
            <div className="site-header__account">
              <AccountSwitcher darkBg />
            </div>
            <nav className="site-header__nav">
              <ul>
                <li>
                  <Link to="/modelRegistry">
                    <KatLink>Model Registry</KatLink>
                  </Link>
                </li>

                <li>
                  <Link to="/cliOnboardingToken">
                    <KatLink>CLI Token</KatLink>
                  </Link>
                </li>
              </ul>
            </nav>
            <div className="flex items-center gap-1">
              <span className="site-header__username">{user.name}</span>
              <img
                className="site-header__user-photo"
                aria-hidden="true"
                src={imgSrc}
                alt=""
              />
            </div>
          </div>
        </div>
      </header>
      {menuOpen && (
        <>
          <div className="site-header__slide-out-panel site-header__slide-out-panel--open">
            <AccountSwitcher
              variant="mobile"
              onChange={() => {
                setMenuOpen(false);
              }}
            />
            <nav className="slide-out-panel__nav">
              <ul>
                <li>
                  <Link
                    to="/modelRegistry"
                    onClick={() => {
                      setMenuOpen(false);
                    }}
                  >
                    <KatLink>Model Registry</KatLink>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/cliOnboardingToken"
                    onClick={() => {
                      setMenuOpen(false);
                    }}
                  >
                    <KatLink>CLI Token</KatLink>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div
            aria-hidden="true"
            className="site-header__overlay site-header__overlay--visible"
            onKeyDown={closeMenu}
            onClick={closeMenu}
          />
        </>
      )}
    </>
  );
};
export default SiteHeader;
