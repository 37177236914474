import { KatAlert, KatList } from '@amzn/katal-react';
import { DataTable } from 'src/components/DataTable/DataTable';
import React from 'react';

interface ErrorsByJobTableProps {
  errorMessages?: { jobId: string; jobType: string; errorMessages: string[] }[];
}

export const ErrorsByJobTable = ({ errorMessages }: ErrorsByJobTableProps) => {
  if (!errorMessages || errorMessages?.length === 0) return <></>;
  return (
    <KatAlert variant="danger" header="Data Quality Monitoring Error">
      <p>
        One or more of the jobs being monitored had an error saving the metrics
        data.
      </p>
      <DataTable
        data={errorMessages}
        columns={[
          {
            accessor: 'jobId',
            Header: 'Job Id',
            width: 100,
          },
          {
            accessor: 'jobType',
            Header: 'Job Type',
            width: 75,
          },
          {
            accessor: 'errorMessages',
            Header: 'Error Messages',
            Cell: (info: any) => {
              return (
                <KatList variant="bullet">
                  {info.value?.map((message: string, index: number) => {
                    return <li key={index}>{message}</li>;
                  })}
                </KatList>
              );
            },
          },
        ]}
        heading="Error Messages by Job"
      />
    </KatAlert>
  );
};
