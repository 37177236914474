import { useState } from 'react';
import { KatDropdown } from '@amzn/katal-react';
import { FeatureSelect } from 'src/components/FeatureSelect';
import { DataQualityMetricsChart } from 'src/components/DataQualityMetricsChart';
import { DataTable } from 'src/components/DataTable/DataTable';
import { CellProps, Column } from 'react-table';
import React from 'react';
import { MetricValueCell } from 'src/components/table-cells/MetricValueCell/MetricValueCell';
import { DateColumn } from 'src/components/DateColumn/DateColumn';

interface FeatureMetricsProps {
  loading: boolean;
  mode: 'numerical' | 'categorical';
  baselineJob?: DataQualityNumericalMetricsDataJob;
  setBaselineJob?: (job: DataQualityNumericalMetricsDataJob) => void;
  data: {
    features: Set<string>;
    jobs:
      | DataQualityNumericalMetricsDataJob[]
      | DataQualityCategoricalMetricsDataJob[];
  } | null;
  selectedFeatures: string[];
  setSelectedFeatures: (features: string[]) => void;
}

export const FeatureMetrics = ({
  loading,
  mode,
  baselineJob,
  setBaselineJob,
  data,
  selectedFeatures,
  setSelectedFeatures,
}: FeatureMetricsProps) => {
  const [selectedMetric, setSelectedMetric] = useState(
    mode === 'numerical' ? 'min' : 'missingPercentage',
  );

  const [showRelativeDate, setShowRelativeDate] = useState<boolean>(false);

  if (!data?.features || data.features.size === 0) {
    return <p className="py-20">No Features of this type detected.</p>;
  }

  const tableColumns: Column[] = [
    ...Array.from(selectedFeatures).reduce<Column[]>((acc, curr) => {
      acc.push({
        Header: curr,
        id: curr,
        // @ts-expect-error
        accessor: [curr]?.[selectedMetric],
        Cell: (rowInfo: CellProps<Record<string, any>>) => {
          return selectedMetric === 'valueCount' ? (
            <p>
              {rowInfo.row.original[curr]?.[selectedMetric].map(
                (item: any, i: number) => <div key={i}>{item}</div>,
              )}
            </p>
          ) : (
            <MetricValueCell
              value={rowInfo.row.original[curr]?.[selectedMetric]}
            />
          );
        },
      });
      return acc;
    }, []),
  ];

  const tableOptions =
    mode === 'numerical'
      ? [
          { name: 'min', value: 'min' },
          { name: 'mean', value: 'mean' },
          { name: 'sum', value: 'sum' },
          { name: 'max', value: 'max' },
          { name: 'Standard Deviation', value: 'stdDev' },
          { name: 'Missing Percentage', value: 'missingPercentage' },
        ]
      : [
          { name: 'Missing Percentage', value: 'missingPercentage' },
          { name: 'Value Counts', value: 'valueCount' },
        ];

  const createdDateColumn = DateColumn({
    header: 'Created Date',
    accessor: 'createdDate',
    id: 'created-date',
    showRelativeDate,
    setShowRelativeDate,
  });

  return (
    <div>
      <div className="mt-10 mb-5 flex justify-between items-end flex-wrap gap-2">
        {setBaselineJob && (
          <div className="min-w-80">
            <KatDropdown
              label="Baseline Job"
              searchable={true}
              value={baselineJob?.jobName ?? ''}
              onChange={(e) => {
                setBaselineJob(
                  // @ts-expect-error
                  data?.jobs.find((job) => job.jobName === e.detail.value) ??
                    null,
                );
              }}
              placeholder="Select a job"
              options={data?.jobs.map((job) => ({
                name: `${job.jobName} - ${job.jobType}`,
                value: job.jobName,
              }))}
            />
          </div>
        )}
        <div className="flex gap-4">
          <FeatureSelect
            setSelectedFeatures={setSelectedFeatures}
            featureList={Array.from(data?.features ?? [])}
          />
        </div>
      </div>
      <DataQualityMetricsChart
        baselineJob={baselineJob ?? undefined}
        mode={mode}
        data={data ?? { features: new Set(), jobs: [] }}
        loading={loading}
        selectedFeatures={selectedFeatures}
      />
      <DataTable
        highLightedRow={{
          columnId: 'jobName',
          value: baselineJob?.jobName ?? '',
        }}
        heading={<h3>{selectedMetric}</h3>}
        controls={
          <div className="min-w-32">
            <KatDropdown
              label="Metric"
              value={selectedMetric}
              options={tableOptions}
              onChange={(e) => setSelectedMetric(e.detail.value)}
            />
          </div>
        }
        columns={[
          {
            Header: 'Job Name',
            accessor: 'jobName',
          },
          createdDateColumn,
          ...tableColumns,
        ]}
        data={data?.jobs ?? []}
      />
    </div>
  );
};
