import { ARN } from '@aws-sdk/util-arn-parser';
import { KatButton, KatIcon } from '@amzn/katal-react';
import React, { useContext, useEffect, useState } from 'react';
import { ApprovalConfig } from 'src/hooks/useApprovalDecision/useApprovalDecision';
import { OperatorInfo } from 'src/components/JobVisualizationNode/OperatorInfo';
import { DecisionTable } from 'src/components/ApprovalOperatorNode/DecisionTable';
import { apiGetRequest } from 'src/utils/mlPigeonAPIRequest/mlPigeonAPIRequest';
import { StatusLabel } from 'src/components/ApprovalOperatorNode/StatusLabel';
import { Link } from 'react-router-dom';
import { UserContext } from 'src/context/userContext';

export interface ApprovalOperatorNodeProps {
  header: string;
  info: {
    id: string;
    operatorName: string;
    operatorFlowId: string;
    modelName?: string;
    modelArn?: ARN;
  };
  status: OperatorJobStatus;
  setApprovalConfig: (arg0: ApprovalConfig) => void;
  setStatusModalMessage: (arg0: { message: string; name: string }) => void;
  setOperatorHelpConfig: (arg: { status: OperatorJobStatus }) => void;
}

export const ApprovalOperatorNode = ({
  header,
  info,
  status,
  setApprovalConfig,
  setOperatorHelpConfig,
}: ApprovalOperatorNodeProps) => {
  const approvalOperatorJobTypeMap: Record<OperatorJobStatus, string> = {
    NOTSTARTED: 'Not Started',
    STARTING: 'Starting',
    PENDING: 'Pending',
    SUCCEEDED: 'Approved',
    FAILED: 'Rejected',
  };
  const user = useContext(UserContext);
  const approvalOperatorFriendlyStatus: string =
    approvalOperatorJobTypeMap[status];

  const [statusLoading, setStatusLoading] = useState<boolean>(false);
  const [jobStatus, setJobStatus] = useState<string | null>(null);
  const [decisions, setDecisions] = useState<ApprovalStatus[]>([]);
  const [requiredApprovals, setRequiredApprovals] = useState(null);
  const [isValidApprover, setIsValidApprover] = useState(true);

  useEffect(() => {
    if (!info.id || ['NOTSTARTED'].includes(status)) {
      setJobStatus(approvalOperatorFriendlyStatus);
      return;
    }

    setStatusLoading(true);
    apiGetRequest({
      endpoint: `approvalOperators/${info.id}`,
    })
      .then((res) => {
        setJobStatus(
          approvalOperatorJobTypeMap[res.status as OperatorJobStatus],
        );
        setRequiredApprovals(res.requiredApprovalCount);
        setDecisions(res.approvalStatuses);

        const userHasDecided =
          res.approvalStatuses?.filter((a: any) => a.createdBy === user.name)
            .length > 0;

        if (userHasDecided) {
          setIsValidApprover(false);
        }
      })
      .catch((e) => {
        console.error(e);
        setJobStatus(approvalOperatorFriendlyStatus);
      })
      .finally(() => {
        setStatusLoading(false);
      });
  }, [status, info.id]);

  return (
    <div className="job-visualization-node w-[730px]">
      <div className="job-visualization-node__header">
        <h3>{header}</h3>
        <div className="job-visualization-node__status-container">
          <StatusLabel loading={false} label={jobStatus} />
          <button
            disabled={status === 'SUCCEEDED'}
            className="icon-button"
            onClick={() => setOperatorHelpConfig({ status })}
            title="Troubleshooting"
          >
            <KatIcon size="small" name="help" />
          </button>
        </div>
      </div>
      <OperatorInfo operatorId={info.id} operatorName={info.operatorName} />
      <div className="approval-node__status-message">
        <DecisionTable loading={statusLoading} decisions={decisions} />
      </div>

      <div className="job-visualization-node__buttons">
        {requiredApprovals && (
          <span className="mr-4">
            <span className="font-bold">
              {`${
                decisions?.filter((d) => d.status === 'SUCCEEDED').length ?? '0'
              } / ${requiredApprovals}`}{' '}
            </span>
            required approvals
          </span>
        )}
        <div className="job-visualization-node__approval_nodes">
          <KatButton
            disabled={status !== 'PENDING' || !isValidApprover}
            size="small"
            variant="primary"
            onClick={() => {
              setApprovalConfig({
                flowId: info.operatorFlowId,
                operatorName: info.operatorName,
                statusMessage: '',
                decision: '',
              });
            }}
          >
            Approve or Reject
          </KatButton>
        </div>
        <Link
          to={`../logs?operatorName=${info.operatorName}`}
          relative="path"
          target="_blank"
        >
          View Logs
        </Link>
      </div>
    </div>
  );
};
